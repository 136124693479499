@tailwind base;
@tailwind components;
@tailwind utilities;
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@layer base {
  @font-face {
    font-family: "Manrope";
    font-weight: 400;
    font-style: normal;
    src: url(../public/assets/fonts/Manrope-Regular.ttf);
  }
  @font-face {
    font-family: "Manrope";
    font-weight: 600;
    font-style: normal;
    src: url(../public/assets/fonts/Manrope-SemiBold.ttf);
  }
  @font-face {
    font-family: "Manrope";
    font-weight: 700;
    font-style: normal;
    src: url(../public/assets/fonts/Manrope-Bold.ttf);
  }

  @font-face {
    font-family: "Quattrocento";
    font-weight: 400;
    font-style: normal;
    src: url(../public/assets/fonts/Quattrocento-Regular.ttf);
  }

  html {
    @apply text-base bg-dark/60 font-manrope;
  }
  body {
    @apply inset-0 !m-auto bg-white;
  }
  .popover-desktop {
    width: 100vw;
    margin-left: calc((100% - 100vw) / 2);
  }
  /** Ionic CSS Variables **/
  :root {
    --max-container-width: 512px;
    --ion-font-family: "Manrope";
    --footer-height: 75px;
    /* https://ionicframework.com/docs/theming/colors */

    --light: #9eaab4;
    --light-rgb: 158, 170, 180;
    --dark-rgb: 150, 150, 150;
    --ion-color-light: var(--light);

    /** RED DARK **/
    --ion-color-red-dark: #94354b;
    --ion-color-red-dark-rgb: 148, 53, 75;
    --ion-color-red-dark-contrast: #ffffff;
    --ion-color-red-dark-contrast-rgb: 255, 255, 255;
    --ion-color-red-dark-shade: #822f42;
    --ion-color-red-dark-tint: #9f495d;

    /** RED **/
    --ion-color-red: #9f1536;
    --ion-color-red-rgb: 159, 21, 54;
    --ion-color-red-contrast: #ffffff;
    --ion-color-red-contrast-rgb: 255, 255, 255;
    --ion-color-red-shade: #8c1230;
    --ion-color-red-tint: #a92c4a;

    --ion-color-primary: var(--ion-color-red);
    --ion-color-primary-rgb: var(--ion-color-red-rgb);
    --ion-color-primary-contrast: var(--ion-color-red-contrast);
    --ion-color-primary-contrast-rgb: var(--ion-color-red-contrast-rgb);
    --ion-color-primary-shade: var(--ion-color-red-shade);
    --ion-color-primary-tint: var(--ion-color-red-tint);

    /** GREEN **/
    --ion-color-green: #157663;
    --ion-color-green-rgb: 21, 118, 99;
    --ion-color-green-contrast: #ffffff;
    --ion-color-green-contrast-rgb: 255, 255, 255;
    --ion-color-green-shade: #126857;
    --ion-color-green-tint: #2c8473;

    /** PINK **/
    --ion-color-pink: #ffbcaf;
    --ion-color-pink-rgb: 255, 188, 175;
    --ion-color-pink-contrast: #000000;
    --ion-color-pink-contrast-rgb: 0, 0, 0;
    --ion-color-pink-shade: #e0a59a;
    --ion-color-pink-tint: #ffc3b7;

    /** YELLOW **/
    --ion-color-yellow: #ffea00;
    --ion-color-yellow-rgb: 255, 234, 0;
    --ion-color-yellow-contrast: #ffffff;
    --ion-color-yellow-contrast-rgb: 255, 255, 255;
    --ion-color-yellow-shade: #ffea00;
    --ion-color-yellow-tint: #ffea00;

    /** GOLD **/
    --ion-color-gold: #cfb57c;
    --ion-color-gold-rgb: 207, 181, 124;
    --ion-color-gold-contrast: #000000;
    --ion-color-gold-contrast-rgb: 0, 0, 0;
    --ion-color-gold-shade: #b69f6d;
    --ion-color-gold-tint: #d4bc89;

    /** BLUE **/
    --ion-color-blue: #1288f2;
    --ion-color-blue-rgb: 18, 136, 242;
    --ion-color-blue-contrast: #ffffff;
    --ion-color-blue-contrast-rgb: 255, 255, 255;
    --ion-color-blue-shade: #1078d5;
    --ion-color-blue-tint: #2a94f3;

    /** DARK **/
    --ion-color-dark-blue: #112637;
    --ion-color-dark-blue-rgb: 17, 38, 55;
    --ion-color-dark-blue-contrast: #ffffff;
    --ion-color-dark-blue-contrast-rgb: 255, 255, 255;
    --ion-color-dark-blue-shade: #0f2130;
    --ion-color-dark-blue-tint: #293c4b;

    /** VIOLET **/
    --ion-color-violet: #98a6ef;
    --ion-color-violet-rgb: 152, 166, 239;
    --ion-color-violet-contrast: #000000;
    --ion-color-violet-contrast-rgb: 0, 0, 0;
    --ion-color-violet-shade: #8692d2;
    --ion-color-violet-tint: #a2aff1;

    /** GREY **/
    --ion-color-grey: #ebf0f5;
    --ion-color-grey-rgb: 235, 240, 244;
    --ion-color-grey-contrast: #000000;
    --ion-color-grey-contrast-rgb: 0, 0, 0;
    --ion-color-grey-shade: #cfd3d7;
    --ion-color-grey-tint: #edf2f5;

    /** WHITE **/
    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255, 255, 255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0, 0, 0;
    --ion-color-white-shade: #e0e0e0;
    --ion-color-white-tint: #ffffff;

    /** BLACK **/
    --ion-color-black: #000000;
    --ion-color-black-rgb: 0, 0, 0;
    --ion-color-black-contrast: #ffffff;
    --ion-color-black-contrast-rgb: 255, 255, 255;
    --ion-color-black-shade: #000000;
    --ion-color-black-tint: #1a1a1a;

    /* --ion-backdrop-color	Color of the Backdrop component
    --ion-backdrop-opacity	Opacity of the Backdrop component
    --ion-overlay-background-color	Background color of the overlays
    --ion-border-color	Border color
    --ion-box-shadow-color	Box shadow color
    --ion-tab-bar-background	Background of the Tab Bar
    --ion-tab-bar-background-focused	Background of the focused Tab Bar
    --ion-tab-bar-border-color	Border color of the Tab Bar
    --ion-tab-bar-color	Color of the Tab Bar
    --ion-tab-bar-color-selected	Color of the selected Tab Button
    --ion-toolbar-background	Background of the Toolbar
    --ion-toolbar-border-color	Border color of the Toolbar
    --ion-toolbar-color	Color of the components in the Toolbar
    --ion-toolbar-segment-color	Color of the Segment Buttons in the Toolbar
    --ion-toolbar-segment-color-checked	Color of the checked Segment Buttons in the Toolbar
    --ion-toolbar-segment-background	Background of the Segment Buttons in the Toolbar
    --ion-toolbar-segment-background-checked	Background of the Segment Buttons in the Toolbar
    --ion-toolbar-segment-indicator-color	Color of the Segment Button indicator in the Toolbar
    --ion-item-background	Background of the Item
    --ion-item-border-color	Border color of the Item
    --ion-item-color	Color of the components in the Item
    --ion-placeholder-color	Color of the placeholder in Inputs */

    /* Background color of the entire app */
    --ion-background-color: var(--ion-color-white);
    /* Background color of the entire app, rgb format */
    --ion-background-color-rgb: var(--ion-color-white-rgb);
    /* Text color of the entire app */
    --ion-text-color: var(--ion-color-black);
    /* Text color of the entire app, rgb format */
    --ion-text-color-rgb: var(--ion-color-black-rgb);

    --ion-color-step-50: #f2f2f2;
    --ion-color-step-100: #e6e6e6;
    --ion-color-step-150: #d9d9d9;
    --ion-color-step-200: #cccccc;
    --ion-color-step-250: #bfbfbf;
    --ion-color-step-300: #b3b3b3;
    --ion-color-step-350: #a6a6a6;
    --ion-color-step-400: #999999;
    --ion-color-step-450: #8c8c8c;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #737373;
    --ion-color-step-600: #666666;
    --ion-color-step-650: #595959;
    --ion-color-step-700: #4d4d4d;
    --ion-color-step-750: #404040;
    --ion-color-step-800: #333333;
    --ion-color-step-850: #262626;
    --ion-color-step-900: #191919;
    --ion-color-step-950: #0d0d0d;
  }

  .ion-color-red {
    --ion-color-base: var(--ion-color-red);
    --ion-color-base-rgb: var(--ion-color-red-rgb);
    --ion-color-contrast: var(--ion-color-red-contrast);
    --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
    --ion-color-shade: var(--ion-color-red-shade);
    --ion-color-tint: var(--ion-color-red-tint);
  }

  .ion-color-green {
    --ion-color-base: var(--ion-color-green);
    --ion-color-base-rgb: var(--ion-color-green-rgb);
    --ion-color-contrast: var(--ion-color-green-contrast);
    --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
    --ion-color-shade: var(--ion-color-green-shade);
    --ion-color-tint: var(--ion-color-green-tint);
  }

  .ion-color-pink {
    --ion-color-base: var(--ion-color-pink);
    --ion-color-base-rgb: var(--ion-color-pink-rgb);
    --ion-color-contrast: var(--ion-color-pink-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
    --ion-color-shade: var(--ion-color-pink-shade);
    --ion-color-tint: var(--ion-color-pink-tint);
  }

  .ion-color-gold {
    --ion-color-base: var(--ion-color-gold);
    --ion-color-base-rgb: var(--ion-color-gold-rgb);
    --ion-color-contrast: var(--ion-color-gold-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
    --ion-color-shade: var(--ion-color-gold-shade);
    --ion-color-tint: var(--ion-color-gold-tint);
  }

  .ion-color-blue {
    --ion-color-base: var(--ion-color-blue);
    --ion-color-base-rgb: var(--ion-color-blue-rgb);
    --ion-color-contrast: var(--ion-color-blue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-shade);
    --ion-color-tint: var(--ion-color-blue-tint);
  }

  .ion-color-dark-blue {
    --ion-color-base: var(--ion-color-dark-blue);
    --ion-color-base-rgb: var(--ion-color-dark-blue-rgb);
    --ion-color-contrast: var(--ion-color-dark-blue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-dark-blue-contrast-rgb);
    --ion-color-shade: var(--ion-color-dark-blue-shade);
    --ion-color-tint: var(--ion-color-dark-blue-tint);
  }

  .ion-color-violet {
    --ion-color-base: var(--ion-color-violet);
    --ion-color-base-rgb: var(--ion-color-violet-rgb);
    --ion-color-contrast: var(--ion-color-violet-contrast);
    --ion-color-contrast-rgb: var(--ion-color-violet-contrast-rgb);
    --ion-color-shade: var(--ion-color-violet-shade);
    --ion-color-tint: var(--ion-color-violet-tint);
  }

  .ion-color-grey {
    --ion-color-base: var(--ion-color-grey);
    --ion-color-base-rgb: var(--ion-color-grey-rgb);
    --ion-color-contrast: var(--ion-color-grey-contrast);
    --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
    --ion-color-shade: var(--ion-color-grey-shade);
    --ion-color-tint: var(--ion-color-grey-tint);
  }

  .ion-color-white {
    --ion-color-base: var(--ion-color-white);
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
  }

  .ion-color-black {
    --ion-color-base: var(--ion-color-black);
    --ion-color-base-rgb: var(--ion-color-black-rgb);
    --ion-color-contrast: var(--ion-color-black-contrast);
    --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
    --ion-color-shade: var(--ion-color-black-shade);
    --ion-color-tint: var(--ion-color-black-tint);
  }

  .swiper {
    width: 100%;
    height: 100%;
    overflow: visible !important;
  }
  .swiper-news-banner {
    overflow: hidden !important;
    padding-bottom: 25px !important;
  }
  .swiper-news-banner .swiper-pagination-bullets {
    bottom: 0 !important;
    text-align: start;
  }
  .swiper-news-banner .swiper-pagination-bullet {
    @apply !bg-light w-[9px] h-[9px];
  }
  .swiper-news-banner .swiper-pagination-bullet-active {
    @apply !bg-dark;
  }

  .swiper-objectives {
    /* overflow: hidden !important; */
    padding-bottom: 25px !important;
  }
  .swiper-objectives .swiper-pagination-bullets {
    bottom: 0 !important;
    text-align: start;
  }
  .swiper-objectives .swiper-pagination-bullet {
    @apply !bg-light w-[9px] h-[9px];
  }
  .swiper-objectives .swiper-pagination-bullet-active {
    @apply !bg-dark;
  }
  @media (prefers-color-scheme: dark) {
  }
}
@layer components {
  .footer-toolbar {
    box-shadow: 0px 5px 15px rgba(17, 38, 55, 0.5);
    border-radius: 30px 30px 0px 0px;
  }
}
