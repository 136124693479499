.calendar {
    @apply text-base leading-normal  text-dark;
}
.calendar :global(.react-calendar__navigation) {
    @apply flex mb-[20px];
}
.calendar :global(.react-calendar__navigation__label) {
    @apply order-1 font-semibold text-left capitalize;
}
.calendar :global(.react-calendar__navigation__prev-button) {
    @apply order-2 mr-[30px];
}
.calendar :global(.react-calendar__navigation__next-button) {
    @apply order-3;
}
.calendar :global(.react-calendar__navigation__next2-button) {
    @apply hidden;
}
.calendar :global(.react-calendar__navigation__prev2-button) {
    @apply hidden;
}
.calendar abbr {
    @apply relative z-10 no-underline capitalize;
}

.calendar :global(.react-calendar__month-view__weekdays) {
    @apply flex items-center justify-between text-light my-[10px];
}
.calendar :global(.react-calendar__month-view__weekdays__weekday) {
    flex: initial !important;
}
.calendar :global(.react-calendar__month-view__days__day--neighboringMonth) {
    @apply text-light;
}
.calendar :global(.react-calendar__tile) {
    @apply relative h-[36px] my-[10px];
    flex: initial !important;
}
.calendar :global(.react-calendar__month-view__days) {
    @apply !grid justify-between;
    grid-template-columns: repeat(7, 36px);
}
.calendar :global(.react-calendar__year-view__months) {
    @apply !grid justify-between;
    grid-template-columns: repeat(4, auto);
}

.calendar :global(.react-calendar__tile):disabled:not(:global(.react-calendar__month-view__days__day)) {
    @apply text-light;
}

.eventTile {
    
}
.eventTile::after {
    content: "";    
    @apply absolute h-full bg-pink w-[36px] inset-0 m-auto z-0 rounded-full;
}